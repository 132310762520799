// Dimensions
$sidebar-width:     275px;

$default-vertical-padding: 2.5em;
$default-horizontal-padding: 1.5em;

// Fonts
$font-default-family:   "Arapey", sans-serif;
$font-default-weight:   400;
$font-default-size:     16px;

$font-highlight-family: "Cinzel", sans-serif;
$font-highlight-weight: 400;
$font-highlight-size:  2em;

$font-quote-family:     "Georgia", serif;
$font-quote-weight:     400;
$font-quote-style:      italic;


// Colors
$color-background-primary:      #fcfcfc;
$color-background-secondary:    rgba(0, 0, 0, 0.25);

$color-separator-primary:   #e7e7e7;

$color-text-primary:        #7b7979;
$color-text-secondary:      #ffffff;


// Media query sizes
$on-tablet:        800px !default;
$on-laptop:        1200px !default;
$on-desktop:       2560px !default;

@mixin media-query($device) {
  @media only screen and (min-width: $device) {
    @content;
  }
}

// Base path for assets (fonts, images...)
$asset-base-path: '../../assets' !default;

// Asset URL builder
@function asset($type, $file) {
  @return url($asset-base-path + '/' + $type + '/' + $file);
}

// Image asset helper
@function image($file) {
  @return asset('images', $file);
}

// Load sub styles
@import "base", "layout", "header", "sidebar", "gallery", "artwork", "page", "theme";
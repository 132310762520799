/*********************************************************/
/* Main structure
/*********************************************************/

body {
  display: block;
}

.site-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
}

.site-header {
  width: 100%;
}

.site-sidebar {
  width: $sidebar-width;
  float: left;
  padding: 2.5em 1em;

  display: none;
}

.site-content {
  width: 100%;
  padding-top: 40px;

  -webkit-overflow-scrolling: touch;
}

.site-footer {
  width: unset;
  padding: 2.5em 0em;
  margin: 0em 1em;

  display: block;
}

@include media-query($on-tablet) {
  html {
    height: 100%;
  }

  body {
    height: 100%;
    overflow: hidden;
    display: flex;
    box-sizing: border-box;
  }

  .site-container {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    overflow-y: auto;
  }

  .site-header {
    display: none;
  }

  .site-content {
    padding-top: 0px;
  }

  .site-sidebar {
    display: flex;
  }

  .site-content {
    width: calc(100% - $sidebar-width);
    float: right;
  }

  .site-footer {
    display: none;
  }
}

/*********************************************************/
/* Grid layout
/*********************************************************/

.grid {
  display: block;
  flex-direction: row;
}

.grid-item {
  width: 100%;
  display: block;
}

.grid-item img {
  display: block;
  width: 100%;
}

@include media-query($on-laptop) {

  .grid {
    display: flex;
    flex-wrap: wrap;
  }

  .grid-item {
    width: calc(100% / 2);
  }
}

@include media-query($on-desktop) {

  .grid-item {
    width: calc(100% / 3);
  }
}

.grid-third {
  max-width: 900px;
  display: flex;
  justify-content: space-between;
}

.grid-item-third {
  width: 32%;
}

.grid-item-third img {
  display: block;
  width: 100%;
}

/*********************************************************/
/* Page
/*********************************************************/

.page-container {
  padding: $default-vertical-padding;
}

.page-image {
  width: 100%;
  float: none;
  margin-left: 0;
  margin-bottom: 0;
}

.page-image img {
  width: 100%;
  height: auto;
}

.page-content {
  float: none;
}

@include media-query($on-tablet) {

  .page-image {
    width: unset;
    float: right;
    margin-left: $default-horizontal-padding;
    margin-bottom: $default-horizontal-padding;
  }

  .page-image img {
    width: auto;
    height: auto;
  }

  .page-content {
    float: none;
  }
}

/*********************************************************/
/* Post
/*********************************************************/

.post-container {
  padding: $default-vertical-padding;
}
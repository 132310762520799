/*********************************************************/
/* Basic theme
/*********************************************************/

body {
  font-family: $font-default-family;
  font-weight: $font-default-weight;
  font-size: $font-default-size;
  color: $color-text-primary;
}

.site-container {
  background: $color-background-primary;
}

.site-logo {
  border-bottom: 1px solid $color-separator-primary;
  margin-bottom: 1em;
  padding-bottom: 2.5em;
}

.site-title {
  font-family: $font-highlight-family;
  font-weight: $font-highlight-weight;
  font-size: $font-highlight-size;
}

.page-header {

}

a {
  color: $color-text-primary;
  text-decoration: none;
  cursor: pointer;
}

p {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.site-footer {
  border-top: 1px solid $color-separator-primary;
}

/*********************************************************/
/* Header theme
/*********************************************************/

.site-header {
  background-color: $color-background-primary;
}

.collapsible-menu {
  background-color: $color-background-primary;
}

/*********************************************************/
/* Sidebar theme
/*********************************************************/

.sidebar-footer {
  border-top: 1px solid $color-separator-primary;
  margin-top: 1em;
  padding-top: 1.5em;
}

/*********************************************************/
/* Artwork theme
/*********************************************************/

.artwork-title {
  font-family: $font-highlight-family;
  font-weight: $font-highlight-weight;
}

/*********************************************************/
/* Page theme
/*********************************************************/

.page-title {
  font-family: $font-highlight-family;
  font-weight: $font-highlight-weight;
  padding-bottom: 0.5em;
}

.page-content p {
  padding-top: 0.25em;
  padding-bottom: 0.25em;
}

/*********************************************************/
/* About page
/*********************************************************/


.artist-biography {
  padding-bottom: 0.5em;
}

.artist-statement {
  padding-top: 1em;
  padding-bottom: 0.5em;
}

.artist-statement blockquote {
  border: 0;
  margin: 0;
  padding: 0;

  font-family: $font-quote-family;
  font-weight: $font-quote-weight;
  font-style: $font-quote-style;
  line-height: 1.5 !important;

  position: relative;
  text-shadow: 0 1px white;
  z-index: 600;
}

.artist-statement blockquote * {
  box-sizing: border-box;
}

.artist-statement blockquote p {
  line-height: 1.5 !important;
}

.artist-statement blockquote p:first-child:before {
  content: '\201C';
  color: #81bedb;
  font-size: 7.5em;
  font-weight: 700;
  opacity: .3;
  position: absolute;
  top: -.4em;
  left: -.2em;
  text-shadow: none;
  z-index: -300;
}

/*********************************************************/
/* Prints page
/*********************************************************/

/*********************************************************/
/* Contact page
/*********************************************************/

.list-icon-items {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 2em;
}

@include media-query($on-tablet) {
  .list-icon-items {
    flex-direction: row;
    align-items: none;
  }
}

.list-icon-items li {
  display: inline-block;
  width: 190px;
}

.list-icon-items a {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.circle {
  width: 120px;
  height: 120px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
}

.circle .icon {
  font-size: 80px;
  line-height: 80px;
}

.pseudo-link {
  white-space: nowrap;
}
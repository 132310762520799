/*********************************************************/
/* Gallery
/*********************************************************/

.gallery-item {
  position: relative;
  width: 100%;
}

.gallery-hover {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;

  transition: .5s ease;
  opacity: 0;
}

.gallery-hover:hover {
  transition: .5s ease;
  background-color: $color-background-secondary;
  opacity: 1;
}

.gallery-text {
  position: absolute;
  width: 100%;
  bottom: 1em;

  color: $color-text-secondary;
  font-size: 1.25em;
  text-shadow: 2px 2px 2px #000;
  text-align: center;
}

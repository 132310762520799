/*********************************************************/
/* Artwork
/*********************************************************/

.artwork-container {
    padding: 0em 0em;

    display: flex;
    flex-direction: column;
}

.artwork-image-box {
    height: 0;
    overflow: hidden;
    padding-top: 100%;
    position: relative;
}

.artwork-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}

.artwork-text {
    width: 100%;
    padding: 1em 1em;

    display: flex;
    flex-direction: column;
}

.artwork-title {
    font-size: 2em;
}

.artwork-description {
    padding: $default-vertical-padding 0em;
    text-align: justify;
}

.artwork-extra {
    align-self: center;
    padding: 1em 0em;
}

@include media-query($on-tablet) {

    .artwork-container {
        padding: $default-vertical-padding $default-vertical-padding $default-vertical-padding 0em;
    }

    .artwork-image-box {
        max-width: 800px;
    }

    .artwork-text {
        padding: 1em 0em;
    }
}

@include media-query($on-laptop) {

    .artwork-container {
        padding: $default-vertical-padding $default-vertical-padding;
        flex-direction: row;
    }

    .artwork-image-box {
        width: 50%;
    }

    .artwork-text {
        width: 50%;
        display: flex;
        flex-direction: column;
        padding: 0em 1em;
    }
}
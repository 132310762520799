/*********************************************************/
/* Header */
/*********************************************************/

.collapsible-menu {
    width: 100%;
    padding: 0px 1em;
    border-bottom: 1px solid $color-separator-primary;

    position: fixed;
    top: 0;
    z-index: 1000;
}

.collapsible-menu ul {
    list-style-type: none;
    padding: 0;
}

.menu-content {
    padding: 0 0 0 2em;
    max-height: 0;
    overflow: hidden;
}

.collapsible-menu a {
    display: block;
    padding: 1em 2.5em;
    text-decoration: none;
}

.collapsible-menu label {
    font-family: $font-highlight-family;
    font-weight: $font-highlight-weight;
    font-size: 1.15em;
    display: block;
    cursor: pointer;
    padding: 0.5em 0 0.5em 2em;
}

@include media-query($on-tablet) {
    .collapsible-menu label {
        font-size: 1.25em;
    }
}

input#menu {
    display: none;
}

label {
    background: image('menu-expand.png') no-repeat left center;
}

input:checked ~ label {
    background: image('menu-collapse.png') no-repeat left center;
}

input:checked ~ .menu-content {
    max-height: 100%;
}
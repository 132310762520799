/*********************************************************/
/* Common
/*********************************************************/

* {
  margin: 0;
  padding: 0;
}

html, body {
  -webkit-font-smoothing: antialiased;
}

ul {
  display: block;
  list-style: none;
}

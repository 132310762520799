/*********************************************************/
/* Page
/*********************************************************/

.page-title {
    font-size: $font-highlight-size;
}

.page-list {
    list-style: disc;
    padding: 1em;
}
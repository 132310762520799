/*********************************************************/
/* Sidebar
/*********************************************************/

.site-logo {
  padding: 0em .5em;
  word-wrap: break-word;
}

.site-nav {
  padding: 1.5em .5em;
  flex: 1;
}

.site-nav-list {
  width: auto;
  margin: 0;
  list-style-type: none;
}

.site-nav-list-item {
  width: auto;
  word-wrap: break-word;
}

.site-nav-list a {
    padding: .5em;
    width: 100%;
    display: inline-block;
}

.site-social-media {
    padding: .5em;
}

.site-copyright {
    padding: .5em;
}

.sidebar-footer {
    display: none;
}

@include media-query($on-tablet) {
    .sidebar-footer {
        width: 100%;
        display: block;
    }
}
